import { origin, ENV_NAME } from './environment.common';
import { BrowserAuthOptions, BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { NavData } from '../app/Models';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const policy = 'B2C_1A_NAM_QA2';
const authOptions: BrowserAuthOptions = {
  clientId: 'c72d940a-78ea-415f-b9cb-7e5637243a25',
  authority: `https://raistoneb2c.b2clogin.com/raistoneb2c.onmicrosoft.com/${policy}`,
  knownAuthorities: ['raistoneb2c.b2clogin.com'],
  redirectUri: origin + '/authorized'
};

const msalConfig: Configuration = {
  auth: authOptions,
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        console.log(message);
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
};

const loginRequest = {
  scopes: []
};

const opsRouteMap: Map<string, NavData[]> = new Map<string, NavData[]>();
opsRouteMap.set('Activity', [
  { label: 'Participant Offers', route: 'activities/participant-offers', isActive: false },
  { label: 'Financing Inbound', route: 'activities/financing-inbound', isActive: false },
  { label: 'Financing Outbound', route: 'activities/financing-outbound', isActive: false },
  { label: 'Cash App Inbound', route: 'activities/cash-app-inbound', isActive: false },
  { label: 'Cash App Outbound', route: 'activities/cash-app-outbound', isActive: false },
  { label: 'Import', route: 'activities/imports', isActive: false }
]);
opsRouteMap.set('Inquiry', [
  {
    label: 'Instruments',
    route: 'inquiry/instruments',
    isActive: false
  },
  {
    label: 'POLs',
    route: 'inquiry/pols',
    isActive: false
  },
  {
    label: 'POBs',
    route: 'inquiry/pobs',
    isActive: false
  },
  {
    label: 'POL Prices',
    route: 'inquiry/pol-prices',
    isActive: false
  }
]);
opsRouteMap.set('Reports', [
  {
    label: 'Generated Reports',
    route: 'reports/generated-reports',
    isActive: false
  }
]);
opsRouteMap.set('Settings', [
  { label: 'Setup', route: 'setup', isActive: false },
  { label: 'Ingest Status', route: 'ingest', isActive: false }
]);

export const environment = {
  envName: ENV_NAME.QA2,
  oauthAllowedUrls: ['https://qa2.ops.raistoneapi.com'],
  origin,
  msalConfig,
  limestoneHost: 'https://qa2.backend.raistoneapi.com',
  appInsights: {
    InstrumentationKey: '1b7c37a0-e496-4c01-9a93-632ecab08d81'
  },
  protectedResources: {
    api: {
      endpoint: 'https://qa2.backend.raistoneapi.com',
      scopes: ['https://raistoneb2c.onmicrosoft.com/api-ls-qa2/user_impersonation']
    }
  },
  opsRouteMap
};
