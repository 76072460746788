import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { ParticipantGroupCohortState } from './participant-group-cohort-reducer';
import copy from 'fast-copy';
import { ReferenceRateSelectors } from '../ReferenceRate/reference-rate-selectors';
import { DiscountBasisSelectors } from '../DiscountBasis/discount-basis-selectors';
import { ParticipantGroupCohortDetailData } from '../../../../Models';
import { ParticipantGroupSelectors } from '../ParticipantGroup/participant-group-selectors';
import { OpsCenterSelectors } from '../OpsCenter/ops-center-selectors';
import { GenericSelectors, LookupTables } from '@limestone/ls-shared-modules';
import { OpsSelectors } from '../Generic/ops-selectors';

@Injectable()
export class ParticipantGroupCohortSelectors {
  constructor(
    private ParticipantGroupSelectors: ParticipantGroupSelectors,
    private GenericSelectors: GenericSelectors,
    private OpsCenterSelectors: OpsCenterSelectors,
    private OpsSelectors: OpsSelectors,
    private ReferenceRateSelectors: ReferenceRateSelectors,
    private DiscountBasisSelectors: DiscountBasisSelectors
  ) {}
  participantGroupCohortState = (state: any) => state.participantGroupCohort;

  selectActiveParticipantGroupCohort = createSelector(
    this.participantGroupCohortState,
    (state: ParticipantGroupCohortState) =>
      state.activeParticipantGroupCohort ? copy(state.activeParticipantGroupCohort) : null
  );

  selectParticipantGroupCohortDetailData = createSelector(
    this.selectActiveParticipantGroupCohort,
    this.ParticipantGroupSelectors.selectActiveParticipantGroup,
    this.OpsCenterSelectors.selectActiveOpsCenter,
    this.GenericSelectors.selectLookup(LookupTables.PurchaseCadenceType),
    this.GenericSelectors.selectLookup(LookupTables.LimitBasisType),
    this.OpsSelectors.selectDiscountMethod,
    this.OpsSelectors.selectDiscountCalcType,
    this.ReferenceRateSelectors.selectMethodsAndTypes,
    this.DiscountBasisSelectors.selectDayCountArrAndDiscountBasisTypes,
    (
      cohort,
      participantGroup,
      opsCenter,
      purchaseCadences,
      limitBasisTypes,
      discountMethods,
      discountCalcTypes,
      { referenceRateMethods, referenceRateTypes },
      { dayCountArr, discountBasisTypes }
    ) => {
      const pgData: ParticipantGroupCohortDetailData = {
        cohort: cohort,
        participantGroup: participantGroup,
        opsCenter: opsCenter,
        purchaseCadences: purchaseCadences,
        limitBasisTypes: limitBasisTypes,
        discountMethods: discountMethods,
        discountCalcTypes: discountCalcTypes,
        referenceRateMethods: referenceRateMethods,
        referenceRateTypes: referenceRateTypes,
        dayCountArr: dayCountArr,
        discountBasisTypes: discountBasisTypes
      };
      return pgData;
    }
  );
}
