import { GenericLookup } from './GenericLookup';
import { ProductCurrency } from './ProductCurrency';

export class Product extends GenericLookup<string> {
  public static readonly SCF: string = 'SCF';
  productCurrencies: ProductCurrency[];

  constructor(id?: string, name?: string, isActive?: boolean, productCurrencies?: ProductCurrency[]) {
    super(id, name, isActive);
    this.productCurrencies = productCurrencies
      ? productCurrencies.map((c) => new ProductCurrency(c.id, c.productId, c.isActive, c.currency))
      : new Array<ProductCurrency>();
  }
}
