import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DateValidation,
  GridColumn,
  NumberValidation,
  RequiredValidation,
  SelectValidation,
  TextValidation
} from '../Models/LsDataGridConfig';
import { DropdownOption } from '../../../../Models';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { DataGridService } from '../Services/data-grid.service';
import { LargeCurrencyDisplayPipe, ShowHiddenCharactersPipe, TimePipe } from '../../../../../Utils/Pipes';
import { EditComponentBase } from '../../../EditComponentBase';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AutocompleteEvent } from '../Models/AutoCompleteEvent';
import { deepEqual } from 'fast-equals';
import { CustomValidators } from '../../../../../Utils/CustomValidators';
import { map, takeUntil } from 'rxjs/operators';
import { DataChangeEvent } from '../Models/DataChangeEvent';
import { SelectChangeEvent } from '../Models/SelectChangeEvent';
import { GenericDropdownOption } from '../../../../Models';
import { createLsTextValidatorArray } from '../../../../../Utils/Validators/ls-text-validator-array.validators';
import { createLsNumberValidatorArray } from '../../../../../Utils/Validators/ls-number-validator-array.validators';
import { createLsDateValidatorArray } from '../../../../../Utils/Validators/ls-date-validator-array.validators';

@Component({
  selector: 'ls-data-grid-cell',
  templateUrl: './grid-cell.component.html',
  styleUrls: ['./grid-cell.component.scss']
})
export class GridCellComponent extends EditComponentBase {
  @Input() element: any;
  @Input() column: GridColumn;
  @Input() autoCompleteOptions: DropdownOption[] = [];

  private cellIsEditing: boolean = false;
  @Input() set isEditing(value: boolean) {
    this.cellIsEditing = value && this.column.enableEdit;
    if (this.cellIsEditing) {
      this.buildForm();
    }
  }
  get isEditing() {
    return this.cellIsEditing;
  }

  @Output() autocompleteChange: EventEmitter<AutocompleteEvent> = new EventEmitter<AutocompleteEvent>();
  @Output() selectChange: EventEmitter<SelectChangeEvent> = new EventEmitter<SelectChangeEvent>();
  @Output() dataChange: EventEmitter<DataChangeEvent> = new EventEmitter<DataChangeEvent>();
  @Output() formIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cellButtonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private currencyPipe: CurrencyPipe,
    protected dataGridService: DataGridService,
    private datePipe: DatePipe,
    private timePipe: TimePipe,
    private decimalPipe: DecimalPipe,
    private showHiddenCharactersPipe: ShowHiddenCharactersPipe,
    private largeCurrencyDisplayPipe: LargeCurrencyDisplayPipe,
    private customValidators: CustomValidators
  ) {
    super(null, null, null, null, null, null);
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.statusChanges
      .pipe(
        takeUntil(this.componentTeardown$),
        map(() => this.formIsValid.emit(this.formGroup.valid))
      )
      .subscribe();
  }

  /**
   * Gets the display value. Will apply transformations if applicable.
   *
   * @return string | number formatted cell display value.
   */
  public getDisplayValue(): string | number {
    let displayVal = this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element);
    if (this.column.currencyCode) {
      displayVal = this.currencyPipe.transform(displayVal, this.column.currencyCode);
    } else if (this.column.dataType === 'checkbox') {
      displayVal = this.element[this.column.value] ? 'done' : null;
    } else if (this.column.dataType === 'date') {
      if (displayVal) {
        displayVal = new Date(displayVal).toISOString().split('T')[0];
      }
    } else if (this.column.dataType === 'time') {
      displayVal = this.timePipe.transform(displayVal);
    } else if (this.column.dataType === 'datetime') {
      if (displayVal) {
        const format = this.column.dateTimeFormat ?? 'yyyy-MM-dd hh:mm aa';
        const timezone = this.column.dateTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
        displayVal = this.datePipe.transform(displayVal, format, timezone);
      }
    } else if (displayVal instanceof DropdownOption || displayVal instanceof GenericDropdownOption) {
      displayVal = displayVal.label();
    } else if (this.column.dataType === 'percent') {
      displayVal = displayVal !== null ? displayVal * 100 : null;
    }
    if (this.column.validation instanceof NumberValidation) {
      if (this.column.dataType === 'number') {
        displayVal = this.largeCurrencyDisplayPipe.transform(displayVal, this.column.validation);
      } else {
        displayVal = this.transformDisplayValue(this.column.dataType, displayVal);
      }
    }
    return this.showHiddenCharactersPipe.transform(displayVal);
  }

  /**
   * Gets the tool tip value. Will apply transformations if applicable.
   *
   * @return string | number formatted cell tool tip value.
   */
  public getToolTip(): string | number {
    let displayVal = null;
    if (this.column.validation instanceof NumberValidation) {
      displayVal = this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element);
      if (+displayVal > 1e12) {
        displayVal = this.transformDisplayValue(this.column.dataType, displayVal);
      } else {
        displayVal = null;
      }
    }
    return this.showHiddenCharactersPipe.transform(displayVal);
  }

  /**
   * Returns transformed display value from Validation.
   *
   * @param validationType Validation type of the column.
   * @param value Value of the cell.
   * @private
   *
   * @return any formatted input value.
   */
  private transformDisplayValue(validationType: string, value: any): any {
    switch (validationType) {
      case 'percent':
      case 'number':
        const validation: NumberValidation = this.column.validation;
        const format = `1.${validation.numberOfDecimals}-${validation.numberOfDecimals}`;
        return this.decimalPipe.transform(value, format);
      default:
        return value;
    }
  }

  // ================ EDIT HANDLING ===================== //

  /**
   * Builds the form given the current element.
   *
   * @private
   */
  private buildForm(): void {
    let value = this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element);
    if (this.column.dataType === 'percent') {
      value = value * 100;
      value = this.transformDisplayValue(this.column.dataType, value);
    }
    // Both o?.value() and value are base classes of DropdownOption, so it is safe to assume both will have an id property.
    const controlState =
      this.column.dataType === 'select' ? this.column.dropdownOptions.find((o) => deepEqual(o?.value(), value)) : value;
    const control = new UntypedFormControl(controlState);
    if (this.column.validation) {
      this.setValidators(control, this.column.dataType, this.column.validation);
    }
    if (this.formGroup.contains(this.column.value)) {
      this.formGroup.get(this.column.value).patchValue(value);
    } else {
      this.formGroup.addControl(this.column.value, control);
    }
  }

  /**
   * Dynamically sets validations based on column data type.
   *
   * @private
   */
  private setValidators(
    control: UntypedFormControl,
    editType: string,
    validators: NumberValidation | DateValidation | TextValidation | SelectValidation | RequiredValidation
  ): void {
    switch (editType) {
      case 'autocomplete':
      case 'text':
        const val = validators as TextValidation;
        control.setValidators(createLsTextValidatorArray(val));
        break;
      case 'percent':
      case 'number':
        const val2 = validators as NumberValidation;
        control.setValidators(createLsNumberValidatorArray(val2));
        break;
      case 'date':
        const val3 = validators as DateValidation;
        control.setValidators(createLsDateValidatorArray(val3, this.customValidators));
        break;
      case 'select':
        const val4 = validators as SelectValidation;
        if (val4.required) {
          control.setValidators(Validators.required);
        }
        break;
      case 'time':
        const val5 = validators as TextValidation;

        if (val5.required) {
          control.setValidators(Validators.required);
        }
        break;
      default:
        const val6 = validators as RequiredValidation;
        if (val6.required) {
          control.setValidators(Validators.required);
        }
        break;
    }
    control.updateValueAndValidity();
  }

  /**
   * Applies changes to data when user hits enter or checks apply on the row.
   */
  public submitEdit(): void {
    if (this.formGroup.valid) {
      this.updateVal();
    }
  }

  /**
   * Updates source data with values from form and removes controls.
   *
   * @private
   */
  private updateVal() {
    try {
      let value = this.formGroup.value[this.column.value];
      if (this.column.dataType === 'percent') {
        value = parseFloat(value) / 100;
      }
      this.dataGridService.setElementValueFromColumnValue(this.element, this.column.value, value);
      this.emitData();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Changes value of AutoComplete to what user has selected.
   */
  public handleAutocompleteChange() {
    const acEvent: AutocompleteEvent = {
      element: null,
      column: this.column.value,
      value: this.formGroup.value[this.column.value]
    };
    this.autocompleteChange.emit(acEvent);
  }

  /**
   * Emits Selected Value When User Makes Selection
   */
  public handleSelectChange() {
    this.selectChange.emit({
      element: this.element,
      column: this.column,
      value: this.formGroup.value[this.column.value]
    });
  }

  /**
   * Disallows user to enter e or E on number inputs.
   *
   * @param input KeyboardEvent from input.
   *
   * @return boolean
   */
  public handleInput(input: KeyboardEvent): boolean {
    if (this.column.dataType === 'number' && (input.key === 'e' || input.key === 'E')) {
      return false;
    }
  }

  /**
   * Checks if the form has errors on the specified control.
   *
   * @param name Name of the control to be checked.
   */
  public controlHasErrors(name: string): boolean {
    const control = this.formGroup.get(name);
    if (control) {
      if (control.errors && !control.touched) {
        return false;
      }
      return control.errors != null;
    } else {
      return false;
    }
  }

  /**
   * Emits data when it has been modified and removes temporary unique identifier
   *
   * @param autoCompleteOption Auto complete option that was selected.
   * @private
   */
  private emitData(autoCompleteOption?: DropdownOption): void {
    const event = new DataChangeEvent(
      this.dataGridService.getElementValueFromColumnValue(this.column.value, this.element),
      autoCompleteOption ?? null
    );
    this.dataChange.emit(event);
  }

  /**
   * Emits when the button cell is clicked.
   *
   * @param event Click event
   * @param index index of the menu button clicked
   */
  public emitCellButtonEvent(event: MouseEvent, index?: number) {
    event.stopPropagation();
    if (index >= 0) {
      this.column.menuButtons[index].handler(this.element);
    }
    this.cellButtonClick.emit(this.element);
  }

  public isCellButtonEnabled(column: GridColumn) {
    return typeof column.enableCellButton !== 'undefined' ? column.enableCellButton : true;
  }

  public autoCompleteDisplayValue(option: DropdownOption): string {
    return option && option.label() ? option.label() : '';
  }
}
