import { DropdownOption } from './DropdownOption';
import { GenericLookup } from './GenericLookup';

export class GenericDropdownOption<T> extends GenericLookup<T> implements DropdownOption {
  constructor(id: T, name: string, isActive?: boolean) {
    super(id, name, isActive);
  }

  label(): string {
    return this.name;
  }

  value(): GenericDropdownOption<T> {
    return this;
  }
}
