import { Lookup } from '@limestone/ls-shared-modules';

export class GenericLookup<T> implements Lookup<T> {
  id: T;
  name: string;
  isActive?: boolean;

  constructor(id?: T, name?: string, isActive?: boolean) {
    this.id = id ?? null;
    this.name = name ?? null;
    this.isActive = isActive ?? null;
  }
}
