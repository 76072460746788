// Angular imports
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './Routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './MaterialModule';
import { EffectsModule } from '@ngrx/effects';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
// Limestone Components
import { AuthorizedComponent, CoreComponent, NotFoundComponent } from './Components';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { AppInsightsService, AuthInterceptor, ErrorHandlerService, ExportService, NavigationService } from './Services';
import { FEATURE_FLAG_CONFIG, FeatureFlagConfig, LsSharedModule } from '@limestone/ls-shared-modules';

// State Management
import * as AppReducer from './AppStateManagement/app-reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LsComponentModule } from './Components/App Components/LsComponentModule';
import { UtilsModule } from '../Utils/UtilsModule';
import { CurrencyPipe } from '@angular/common';
import { RouteAuthorizationGuard } from './Guards/RouteAuthorization.guard';
import { RouteAuthorizationService } from './Services/RouteAuthorization.service';
import { UserDataResolver } from './Routing/UserData.resolver';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { HttpErrorService } from './Services/HTTP';
import { BankingService } from './banking-module/Services';
import { PascalToTitlePipe, LargeCurrencyDisplayPipe } from '../Utils/Pipes';
import { SignalRService } from './Services/signalr.service';
import { FeatureFlagsResolver } from './Routing/FeatureFlags.resolver';
import { AppSelectors } from './AppStateManagement';
import {
  FeatureFlagService,
  FeatureFlagReducer,
  FeatureFlagSelectors,
  FeatureFlagEffects
} from '@limestone/ls-shared-modules';
import { AppEffects } from './AppStateManagement/app-effects';
import { AppService } from './Services/app.service';
import { ErrorHandlerInterceptor } from './Services/error-handler.interceptor';
export const FF_CONFIG: FeatureFlagConfig = {
  isPortal: false
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    AuthorizedComponent,
    CoreComponent,
    PascalToTitlePipe,
    LargeCurrencyDisplayPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    LsComponentModule,
    LayoutModule,
    ScrollingModule,
    UtilsModule,
    StoreModule.forRoot(
      { app: AppReducer.reducer, featureFlags: FeatureFlagReducer.reducer },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    StoreDevtoolsModule.instrument({
      name: 'Limestone.UI',
      maxAge: 25 // Retains last 25 states
    }),
    EffectsModule.forRoot([FeatureFlagEffects, AppEffects]),
    MsalModule.forRoot(
      new PublicClientApplication(environment.msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: environment.protectedResources.api.scopes
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.protectedResources.api.endpoint, environment.protectedResources.api.scopes]
        ])
      }
    ),
    LsSharedModule
  ],
  providers: [
    RouteAuthorizationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: FEATURE_FLAG_CONFIG,
      useValue: FF_CONFIG
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    AppInsightsService,
    CurrencyPipe,
    RouteAuthorizationService,
    UserDataResolver,
    FeatureFlagsResolver,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    HttpErrorService,
    BankingService,
    PascalToTitlePipe,
    LargeCurrencyDisplayPipe,
    NavigationService,
    ExportService,
    SignalRService,
    FeatureFlagService,
    FeatureFlagSelectors,
    AppSelectors,
    AppService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
