import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { CompanyState } from './company-reducer';

@Injectable()
export class CompanySelectors {
  appState = (state: any) => state.company;

  selectCompanies = createSelector(this.appState, (state: CompanyState) =>
    state.companyPages == null ? null : copy(state.companyPages)
  );

  selectActiveCompany = createSelector(this.appState, (state: CompanyState) =>
    state.activeCompany == null ? null : copy(state.activeCompany)
  );

  selectAutocompleteCompanies = createSelector(this.appState, (state: CompanyState) =>
    copy(state.companyAutocompleteOptions)
  );

  selectCompaniesComponentState = createSelector(this.appState, (state: CompanyState) => ({
    companyPages: state?.companyPages,
    companiesActivePage: state?.companiesActivePage
  }));
}
