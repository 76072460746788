import { Component } from '@angular/core';
import { NavigationService } from './Services';
import { Store } from '@ngrx/store';
import { AppActions } from './AppStateManagement';

@Component({
  selector: 'ls-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public navigation: NavigationService, private store: Store) {
    this.navigation.startSaveHistory();
    this.store.dispatch(AppActions.getConfig());
  }
}
