import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ReferenceRateType, ReferenceRateMethod, ReferenceRatePublication } from '../../../../Models';

export abstract class ReferenceRateActions extends LsAction {
  static getReferenceRateTypes = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-TYPES', ActionType.GET)
  );

  static setReferenceRateTypes = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-TYPES', ActionType.SET),
    props<{ referenceRateTypes: ReferenceRateType[] }>()
  );

  static getReferenceRateMethods = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-METHODS', ActionType.GET)
  );

  static setReferenceRateMethods = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-METHODS', ActionType.SET),
    props<{ referenceRateMethods: ReferenceRateMethod[] }>()
  );

  static getReferenceRatePublication = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-PUBLICATION', ActionType.GET),
    props<{ publicationDate: Date; referenceRateType: string }>()
  );

  static setReferenceRatePublication = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-PUBLICATION', ActionType.SET),
    props<{ referenceRatePublication: ReferenceRatePublication }>()
  );

  static saveReferenceRatePublication = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-PUBLICATION', ActionType.SAVE),
    props<{ referenceRatePublication: ReferenceRatePublication }>()
  );

  // eslint-disable-next-line prettier/prettier
  static referenceRateSaveSuccessful = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-PUBLICATION', ActionType.COMPLETE)
  );

  static referenceRateSaveUnsuccessful = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-PUBLICATION', ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static setActiveReferenceRateType = createAction(
    ReferenceRateActions.getActionName('REFERENCE-RATE-TYPE', ActionType.SET),
    props<{ referenceRateType: ReferenceRateType }>()
  );
}
