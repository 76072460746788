import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import copy from 'fast-copy';
import { CoreComponent } from './CoreComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { Lookup } from '@limestone/ls-shared-modules';

type handledErrorMessages =
  | 'required'
  | 'min'
  | 'max'
  | 'maxlength'
  | 'matDatepickerParse'
  | 'pattern'
  | 'duplicateDate'
  | 'nameExists'
  | 'lsIntegerPattern'
  | 'lsCurrencyPattern'
  | 'lsTimeRange'
  | 'lsRatePattern'
  | 'minTenorGreaterEqualThanMaxTenor'
  | 'graceDaysGreaterThanDeemedDisputeDays'
  | 'deemedDisputeDaysGreaterThanWriteOffDays'
  | 'offerCutoffGreaterEqualParticipantCutoff';

export class CommonMethodComponentBase extends CoreComponent {
  public originalData: any;
  public formGroup: FormGroup;
  public errors: string[] = [];
  public errorMsgMap: Map<handledErrorMessages, string> = new Map();

  constructor(public router: Router, public activatedRoute: ActivatedRoute) {
    super(router, activatedRoute);
  }

  revertData(): any {
    return copy(this.originalData);
  }

  controlHasErrors(name: string, formGroup?: UntypedFormGroup) {
    let control: AbstractControl;
    if (formGroup) {
      control = formGroup.get(name);
    } else {
      control = this.formGroup.get(name);
    }
    if (control) {
      if (control.errors) {
        if (
          (control.touched && control.errors.hasOwnProperty('required')) ||
          control.errors.hasOwnProperty('maxlength')
        ) {
          return true;
        }
      }
      return control.errors != null;
    }
  }

  getControl(name: string): FormControl {
    return this.formGroup.get(name) as FormControl;
  }

  /**
   * Returns the specific error messages based on which validation failed.
   *
   * @param controlName Name of the control which has errors.
   * @param formGroup used if validating a child form group (such as a form array)
   *
   * @return string of the errors.
   */
  getErrorMessage(controlName: string, formGroup?: UntypedFormGroup) {
    let errorObj: ValidationErrors;
    if (formGroup) {
      errorObj = formGroup.get(controlName)?.errors;
    } else {
      errorObj = this.formGroup.get(controlName)?.errors;
    }

    return this.getErrorMessageFromErrors(errorObj);
  }

  getErrorMessageFromErrors(errors: ValidationErrors | null): string {
    let errorStr = '';
    if (errors === null) {
      return errorStr;
    }
    Object.keys(errors).forEach((key) => {
      switch (key) {
        case 'required':
          errorStr = this.errorMsgMap.has('required') ? this.errorMsgMap.get('required') : 'Required';
          break;
        case 'min':
          errorStr += this.errorMsgMap.has('min')
            ? this.errorMsgMap.get('min')
            : `The value is below the minimum of ${errors[key].min}`;
          break;
        case 'max':
          errorStr += this.errorMsgMap.has('max')
            ? this.errorMsgMap.get('max')
            : `The value exceeds the maximum of ${errors[key].max}`;
          break;
        case 'maxlength':
          errorStr += this.errorMsgMap.has('maxlength')
            ? this.errorMsgMap.get('maxlength')
            : `Max length of ${errors[key].requiredLength} exceeded`;
          break;
        case 'matDatepickerParse':
          errorStr += this.errorMsgMap.has('matDatepickerParse')
            ? this.errorMsgMap.get('matDatepickerParse')
            : 'Invalid Format';
          break;
        case 'pattern':
          errorStr += this.errorMsgMap.has('pattern') ? this.errorMsgMap.get('pattern') : 'Invalid format';
          break;
        case 'duplicateDate':
          errorStr += this.errorMsgMap.has('duplicateDate')
            ? this.errorMsgMap.get('duplicateDate')
            : 'Date already exists on this calendar';
          break;
        case 'nameExists':
          errorStr += this.errorMsgMap.has('nameExists')
            ? this.errorMsgMap.get('nameExists')
            : 'An Entity With That Name Already Exists';
          break;
        case 'lsIntegerPattern':
          errorStr += this.errorMsgMap.has('lsIntegerPattern')
            ? this.errorMsgMap.get('lsIntegerPattern')
            : 'Invalid input, only whole integers allowed.';
          break;
        case 'lsCurrencyPattern':
          errorStr += this.errorMsgMap.has('lsCurrencyPattern')
            ? this.errorMsgMap.get('lsCurrencyPattern')
            : 'Current input does not match the decimal pattern for provided currency.';
          break;
        case 'lsRatePattern':
          errorStr += this.errorMsgMap.has('lsRatePattern')
            ? this.errorMsgMap.get('lsRatePattern')
            : 'Current input does not match the decimal pattern for a Limestone Rate.';
          break;
        case 'lsTimeRange':
          errorStr += this.errorMsgMap.has('lsTimeRange')
            ? this.errorMsgMap.get('lsTimeRange')
            : 'Current input breaks time range check.';
          break;
        case 'minTenorGreaterEqualThanMaxTenor':
          errorStr += this.errorMsgMap.has('minTenorGreaterEqualThanMaxTenor')
            ? this.errorMsgMap.get('minTenorGreaterEqualThanMaxTenor')
            : 'Max Tenor must be greater than Min Tenor.';
          break;
        case 'graceDaysGreaterThanDeemedDisputeDays':
          errorStr += this.errorMsgMap.has('graceDaysGreaterThanDeemedDisputeDays')
            ? this.errorMsgMap.get('graceDaysGreaterThanDeemedDisputeDays')
            : 'Deemed Dispute Days must be greater than or equal to Grace Days.';
          break;
        case 'deemedDisputeDaysGreaterThanWriteOffDays':
          errorStr += this.errorMsgMap.has('deemedDisputeDaysGreaterThanWriteOffDays')
            ? this.errorMsgMap.get('deemedDisputeDaysGreaterThanWriteOffDays')
            : 'Write Off Days must be greater than or equal to Deemed Dispute Days.';
          break;
        case 'offerCutoffGreaterEqualParticipantCutoff':
          errorStr += this.errorMsgMap.has('offerCutoffGreaterEqualParticipantCutoff')
            ? this.errorMsgMap.get('offerCutoffGreaterEqualParticipantCutoff')
            : 'Offer Cutoff Time cannot be later than Participant Acceptance Cutoff Time.';
          break;
      }
    });
    return errorStr;
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  lookupsEqualAndNotNull(a: Lookup<number | string>, b: Lookup<number | string>): boolean {
    return a && b && a.id === b.id;
  }
}
