import { NgModule } from '@angular/core';
import { AccordionComponent } from './accordion-component/accordion.component';
import { MaterialModule } from 'src/app/MaterialModule';
import { CommonModule } from '@angular/common';
import {
  DataGridComponent,
  GridFilterModalComponent,
  ColumnSorterComponent,
  DataGridPaginatorComponent,
  GridHeaderComponent,
  GridCellComponent
} from './data-grid';
import { ButtonComponent } from './button/button.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { KeyValueComponent } from './key-value/key-value.component';
import { LsSharedModule } from '@limestone/ls-shared-modules';

@NgModule({
  imports: [MaterialModule, CommonModule, LsSharedModule],
  declarations: [
    AccordionComponent,
    DataGridComponent,
    DataGridPaginatorComponent,
    GridFilterModalComponent,
    GridHeaderComponent,
    GridCellComponent,
    ColumnSorterComponent,
    ButtonComponent,
    ErrorModalComponent,
    KeyValueComponent
  ],
  exports: [AccordionComponent, DataGridComponent, ButtonComponent, KeyValueComponent]
})
export class LsComponentModule {}
