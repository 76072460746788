import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';

@Component({
  selector: 'ls-core',
  template: ''
})
export class CoreComponent implements OnDestroy {
  protected componentTeardown$ = new Subject();
  constructor(public router: Router, public activatedRoute: ActivatedRoute) {}
  public isGridHidden: boolean;

  navToChild(id: number | string) {
    this.router.navigate([id], { relativeTo: this.activatedRoute });
  }

  navToParent(levels: number = 1): void {
    const path = this.router.routerState.snapshot.url.split('/');
    path.splice(path.length - levels, levels);
    this.router.navigate(path, { replaceUrl: true });
  }

  navToView(path: string | UrlTree): void {
    this.router.navigateByUrl(path);
  }

  ngOnDestroy() {
    this.componentTeardown$.next(null);
    this.componentTeardown$.complete();
  }

  public isLoadingInProgress(isLoading: boolean) {
    this.isGridHidden = isLoading;
  }
}
