import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from 'src/app/Models/Company';
import { Currency } from 'src/app/Models';

@Injectable()
export class BankingService {
  constructor(private http: HttpClient) {}

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>('/api/company');
  }

  getCurrencyCodes(): Observable<Currency[]> {
    return this.http.get<Currency[]>('/api/currency');
  }

  getAccount(companyIds: number[], ledgerDate: string, currencyCodes: string[]): Observable<any[]> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const body = {
      companyIds,
      ledgerDate,
      currencyCodes
    };
    return this.http.post<any[]>('/api/account/search', body, { headers });
  }
}
