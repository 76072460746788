import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { from, Observable, Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class SignalRService {
  private readonly notificationHubConnection: signalR.HubConnection;
  notifications: Observable<string> = new Observable();
  private readonly appConfigurationChangesHubConnection: signalR.HubConnection;
  appConfigurationChanges: Observable<string> = new Observable();

  constructor() {
    this.notificationHubConnection = this.buildSignalRHub(environment.limestoneHost + '/notifications');
    this.notifications = this.createSignalRObservable(this.notificationHubConnection, ['broadcast', 'echo']);
    this.appConfigurationChangesHubConnection = this.buildSignalRHub(
      environment.limestoneHost + '/appconfigurationchanges'
    );
    this.appConfigurationChanges = this.createSignalRObservable(this.appConfigurationChangesHubConnection, [
      'broadcast'
    ]);
  }

  buildSignalRHub(url: string): signalR.HubConnection {
    // establish a Hub Connection with specified url
    const hubConnection = new signalR.HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();

    // start the connection
    from(hubConnection.start()).pipe(
      tap(() => console.log('Connection started')),
      catchError((error: any, caught: Observable<unknown>): Observable<any> => {
        console.log('Error while starting connection: ' + error);
        return caught;
      })
    );

    return hubConnection;
  }

  createSignalRObservable(hubConnection: signalR.HubConnection, methods: string[]): Observable<any> {
    const subject: Subject<any> = new Subject<any>();

    methods.forEach((method) => {
      // handle messages invoked with the specified method, e.g., 'broadcast' or 'echo'
      hubConnection.on(method, (...args: any[]) => {
        // multicast the event
        subject.next(args);
      });
    });

    // handle connection close
    hubConnection.onclose((error?: Error) => {
      if (error) {
        // an error has occurred
        subject.error(error);
      } else {
        // no more events will be sent
        subject.complete();
      }
    });

    // allow subscription by multiple components
    return subject.asObservable();
  }

  private send(signalRMessage: SignalRMessage) {
    switch (signalRMessage.hub) {
      case 'notification':
        this.notificationHubConnection.send(signalRMessage.method, signalRMessage.message);
        break;
      default:
        break;
    }
  }

  public broadcastNotification(message: string) {
    const notificationMessage: SignalRNotificationMessage = {
      hub: 'notification',
      method: 'broadcast',
      message
    };
    this.send(notificationMessage);
  }

  public echoNotification(message: string) {
    const notificationMessage: SignalRNotificationMessage = {
      hub: 'notification',
      method: 'echo',
      message
    };
    this.send(notificationMessage);
  }
}

class SignalRMessage {
  hub: 'notification' | 'appConfig';
  method: string;
  message: string;
}

export class SignalRNotificationMessage extends SignalRMessage {
  method: 'broadcast' | 'echo';
  message: string;

  constructor() {
    super();
    this.hub = 'notification';
  }
}
