import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ParticipantOffer, Page, PageRequest, ExportRequest, ActivePage } from '../../../../Models';

export abstract class ParticipantOfferActions extends LsAction {
  /* eslint-disable */
  static PARTICIPANT_OFFERS: Readonly<string> = 'PARTICIPANT_OFFERS';
  static PARTICIPANT_OFFERS_EXPORT: Readonly<string> = 'PARTICIPANT_OFFERS/EXPORT';
  static PARTICIPANT_OFFERS_ACTIVE_PAGE: Readonly<string> = 'PARTICIPANT_OFFERS_ACTIVE_PAGE';
  /* eslint-enable */

  static getParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.GET),
    props<{ pageRequest: PageRequest }>()
  );

  static setParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.SET),
    props<{ page: Page<ParticipantOffer> }>()
  );

  static clearParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.CLEAR)
  );

  static updateParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.UPDATE),
    props<{ participantOffers: ParticipantOffer[]; pageRequest: PageRequest }>()
  );

  static triggerGenerateParticipantOffers = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.TRIGGER),
    props<{ participantOffers: ParticipantOffer[]; pageRequest: PageRequest }>()
  );

  static participantOffersSaveSuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.COMPLETE)
  );

  static participantOffersSaveUnsuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static triggerParticipantOffersExport = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_EXPORT, ActionType.TRIGGER),
    props<{ exportRequest: ExportRequest }>()
  );

  static participantOffersExportComplete = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_EXPORT, ActionType.COMPLETE)
  );

  static participantOffersExportUnsuccessful = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getParticipantOffersActivePage = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_ACTIVE_PAGE, ActionType.GET)
  );

  static setParticipantOffersActivePage = createAction(
    ParticipantOfferActions.getActionName(ParticipantOfferActions.PARTICIPANT_OFFERS_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );
}
