import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { Injectable } from '@angular/core';
import { FacilityConfigurationState } from './facility-configuration-reducer';

@Injectable()
export class FacilityConfigurationSelectors {
  appState = (state: any) => state.facilityConfiguration;

  selectFacilityConfigDates = createSelector(this.appState, (state: FacilityConfigurationState) =>
    copy(state.facilityConfigDates)
  );

  selectActiveFacilityConfig = createSelector(this.appState, (state: FacilityConfigurationState) =>
    state.activeFacilityConfig == null ? null : copy(state.activeFacilityConfig)
  );

  selectFacilityConfigsComponentState = createSelector(this.appState, (state: FacilityConfigurationState) => ({
    facilityConfigPages: copy(state?.facilityConfigPages),
    facilityConfigsActivePage: copy(state?.facilityConfigsActivePage)
  }));
}
